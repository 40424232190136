<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'super-admin-home' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'super-master-main' }" class="root">
                Super Admin Master
            </router-link>
            <span class="child">/ Links Master </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center"> Links</v-card-title>
            <v-card-text>
                <v-data-table :headers="headers" :items="roleLink_list" class="elevation-1 nowrap" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details> </v-text-field>
                            <span style="width: 20px;"></span>
                                    <v-btn class="mb-2 button" @click="addLinkDialoge">Add  Link</v-btn>

                            <v-dialog v-model="dialog" max-width="700px">
                                <v-card>
                                    <v-form v-model="valid" ref="form">
                                        <v-card-title class="heading">
                                            <span class="headline">{{ formTitle }}</span>
                                        </v-card-title>

                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="6" sm="6" md="6" >
                                                        <label>Link Name</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            v-model="editedItem.roleLink"
                                                            item-text="roleLink_list_id"
                                                            item-value="id"
                                                            persistent-hint
                                                            :error="errorMsg.roleLink?true:false"
                                                            :error-messages="errorMsg.roleLink?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6" sm="6" md="6" >
                                                        <label>Link</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            v-model="editedItem.roleLinkName"
                                                            item-text="roleLink_list_id"
                                                            item-value="id"
                                                            persistent-hint
                                                            :error="errorMsg.roleLinkName?true:false"
                                                            :error-messages="errorMsg.roleLinkName?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6" sm="6" md="6" >
                                                        <label>Action Name</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            v-model="editedItem.actionName"
                                                            item-text="roleLink_list_id"
                                                            item-value="id"
                                                            persistent-hint
                                                            :error="errorMsg.actionName?true:false"
                                                            :error-messages="errorMsg.roleLink?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6" sm="6" md="6" >
                                                        <label>Controller Name</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            v-model="editedItem.controllerName"
                                                            item-text="roleLink_list_id"
                                                            item-value="id"
                                                            persistent-hint
                                                            :error="errorMsg.controllerName?true:false"
                                                            :error-messages="errorMsg.roleLink?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6" sm="6" md="6" >
                                                        <label>Link Icon</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            v-model="editedItem.roleLinkIcon"
                                                            item-text="roleLink_list_id"
                                                            item-value="id"
                                                            persistent-hint
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6" sm="6" md="6" >
                                                        <label> Link Sort Order</label>
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            v-model="editedItem.roleLinkSortOrder"
                                                            item-text="roleLink_list_id"
                                                            item-value="id"
                                                            type="number"
                                                            persistent-hint
                                                            :error="errorMsg.roleLinkSortOrder?true:false"
                                                            :error-messages="errorMsg.roleLinkSortOrder?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6" sm="6" md="6" >
                                                        <label>Choose Role</label>
                                                        <v-autocomplete
                                                            :items="role_list"
                                                            outlined
                                                            dense
                                                            item-value="role_id"
                                                            item-text="role"
                                                            v-model="editedItem.roleId"
                                                            :error="errorMsg.roleId?true:false"
                                                            :error-messages="errorMsg.roleId?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="6" sm="6" md="6" >
                                                        <label>Choose Uesr Type</label>
                                                        <v-autocomplete
                                                            :items="ut_list"
                                                            outlined
                                                            dense
                                                            item-value="ut_id"
                                                            item-text="u_type"
                                                            v-model="editedItem.ut_id"
                                                            :error="errorMsg.ut_id?true:false"
                                                            :error-messages="errorMsg.ut_id?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <!-- <v-col cols="12" sm="12" md="12">
                                                        <v-checkbox dense v-model="editedItem.isactive" color="success" label="Active"></v-checkbox>
                                                    </v-col> -->
                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn class="button-cancel" text @click="close">Cancel</v-btn>
                                            <v-btn class="button" @click="save" :disabled="!valid">Save</v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>

                    <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive">
                            <v-icon class="mr-2 v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                            <v-icon class="mr-2 v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                        </span>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <div>
                            <v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon>
                        </div>
                    </template>
                    <template v-slot:item.edit="{ item }">
                        <v-switch v-model="item.isactive" @change="activeOn(item)"></v-switch>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            valid: false,
            overlay: false,
            errorMsg: {
                roleLinkId: false,
            },
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            org: "",
            dialog: false,
            headers: [
                {
                    text: "Link Name",
                    align: "left",
                    sortable: false,
                    value: "roleLink",
                },
                {
                    text: "Link",
                    align: "left",
                    sortable: false,
                    value: "roleLinkName",
                },
                {
                    text: "Link Icon",
                    align: "left",
                    sortable: false,
                    value: "roleLinkIcon",
                },
                {
                    text: "Sort Order",
                    align: "left",
                    sortable: false,
                    value: "roleLinkSortOrder",
                },
                {
                    text: "Role",
                    align: "left",
                    sortable: false,
                    value: "role",
                },
                {
                    text: "Uesr Type",
                    align: "left",
                    sortable: false,
                    value: "ut",
                },
                {
                    text: "Active",
                    align: "left",
                    sortable: true,
                    value: "isactive",
                },
                // {
                //     text: "Action Name",
                //     align: "left",
                //     sortable: true,
                //     value: "actionName",
                // },
                // {
                //     text: "Controller",
                //     align: "left",
                //     sortable: true,
                //     value: "controllerName",
                // },
                { text: "Actions", value: "action", sortable: false },
                 { text: "Active", value: "edit", sortable: false },
            ],
            roleLink_list: [],
            role_list: [],
            ut_list: [],
            editedIndex: -1,
            editedItem: {
                id: "",
                roleLinkName: "",
                roleLink: "",
                roleLinkIcon: "",
                roleLinkDescription: "",
                roleLinkSortOrder: "",
                role_list: "",
                roleListId: "",
                role: "",
                isactive: false,
                actionName: "",
                controllerName: "",
                ut_id: null,
                roleId: null,
            },
            defaultItem: {
                id: "",
                roleLinkName: "",
                roleLink: "",
                roleLinkIcon: "",
                roleLinkDescription: "",
                roleLinkSortOrder: "",
                role_list: "",
                roleListId: "",
                role: "",
                isactive: false,
                actionName: "",
                controllerName: "",
                ut_id: null,
                roleId: null,
            },
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Link" : "Edit  Link";
            },
        },

        // watch: {
        //     dialog(val) {
        //         val || this.close();
        //     },
        //     overlay(val) {
        //         val = () => {
        //             this.overlay = false;
        //         };
        //     },
        // },

        mounted() {
            this.onLoad();
        },
        methods: {
            activeOn(item) {
                console.log(item);
                axios
                    .post("/SuperAdmin/roleLinksIsactive", item)
                    .then(() => {
                        // this.sec_mode_list = res.data.user_list;
                        this.showSnackbar("#4CAF50", "Link updated successfully...");
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },

addLinkDialoge(){
    this.dialog = true
    this.$refs.form.reset()
},

            editItem(item) {
                this.editedIndex = this.roleLink_list.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
                var name = item.role;
                for (let x in this.role_list) {
                    if (this.role_list[x].name == name) {
                        this.editedItem.role = this.role_list[x].name;
                        this.editedItem.roleId = this.role_list[x].id;
                        break;
                    }
                }
            },
            onLoad() {
                this.overlay = true;
                axios
                    .post("/SuperAdmin/adminAddRoleLink")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.roleLink_list = res.data.roleLink_list;
                            this.role_list = res.data.roles;
                            this.ut_list = res.data.uts;
                            this.org = res.data.org;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            save() {
                if (this.editedIndex > -1) {
                    if (this.isValidated()) {
                        axios
                            .post("/SuperAdmin/editAdminAddRoleLink", this.editedItem)
                            .then((res) => {
                              //alert(res.data.status.code)
                                if (res.data.status.code == "SUCCESS") {
                                    //window.alert(res.data.msg)
                                    this.onLoad();
                                    Object.assign(this.roleLink_list[this.editedIndex], this.editedItem);
                                    this.showSnackbar("#4caf50", " Link Updated Successfully..."); // show snackbar on success
                                    //this.onLoad();
                                } else if (res.data.status.code == "NA") {
                                    this.showSnackbar("#b71c1c", " Link Already Present!!!"); // show snackbar on error
                                }
                            })
                            .catch((error) => {
                                window.console.log(error);
                            });
                        this.close();
                    }
                } else {
                    axios
                        .post("/SuperAdmin/saveAdminAddRoleLink", this.editedItem)
                        .then((res) => {
                            if (res.data.status.code == "SUCCESS") {
                                this.showSnackbar("#4caf50", "Role Link Added Successfully!!!"); // show snackbar on success
                                this.onLoad();
                            } else if (res.data.status.code == "NA") {
                                this.showSnackbar("#b71c1c", "Role Link Already Present!!!"); // show snackbar on error
                            }
                        })
                        .catch((error) => {
                            window.console.log(error);
                        });
                    this.close();
                }
            }, //save()
            isValidated() {
                if (this.editedItem.roleLinkId) {
                    return true;
                } else {
                    if (!this.editedItem.roleLinkId) {
                        this.errorMsg.roleLinkId = true;
                    }
                    return false;
                }
            }, // .....end of isValidated()
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
</style>
